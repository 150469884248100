body {
  position: relative;
  color: #000000;
  height: 100vh;
  background: rgb(46, 46, 46);
  font-family: sans-serif;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  margin: 0px !important;
}

#root {
  padding: 0px !important;
  margin: 0px !important;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

